import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSettingsPaymentsButtons as getSettingsPaymentsButtonsAction,
  addRuleZone as addRuleZoneAction,
  updateSettingsPayments as updateSettingsPaymentsAction,
} from "../../../redux/modules/settings";


import UiPageText from "../../../components/text/UiPageText"; 
import UiTableAdaptive from "../../../components/table/UiTableAdaptive"; 
 
import "../SettingStyle.scss";

function DeliveryPaymentsPage(props) {
  const intervalTitles = [

    { sort: false, title: "Название" },
    { sort: false, title: "Тип" }, 
    { sort: false, title: "Активность" }, 
  ];

  const [deliveryZoneObj, setDeliveryZoneObj] = useState({
    title: null,
    min: 0,
    max: 0
  }); 

  const [intervalList, setIntervalList] = useState([]);

 
  useEffect(() => {
    props.getSettingsPaymentsButtons();
  }, [props.login.user]);


  useEffect(() => {
    let _data = [];
    props.settings.paymentsButtonsList.map((item, index) => {

      let listItem = [
        {
          text: item.title,
          

        },

        {
          text: item.moving_type == 0 ? `Доставка` : `Самовывоз`,
          
          

        },
 

        {
          button:  "eye" ,
          color: item.is_disable == 1 ? "red" : "gray",
          onClick: () => {
            if (window.confirm("Скрыть элемент ?")) {
              let _item = item;
              _item.is_disable = item.is_disable == 1 ? 0 : 1;
              props.updateSettingsPayments(_item);
            }
          },
        },
      ];
      _data.push(listItem)
    })
    setIntervalList(_data)

  }, [ props.settings.paymentsButtonsList ]);


  /**
 *
 * @param {string} key
 * @param {any} val
 */
  function updateDZObj(key, val) {
    let _obj = deliveryZoneObj;
    _obj[key] = val;
    setDeliveryZoneObj({ ..._obj });
  }


  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройка кноопок типов оплаты" />
        </div>
      </section>
      <section className="page-content">

        <UiTableAdaptive
          titleList={intervalTitles}
          grid={intervalTitles.length}
          tableList={intervalList}
        />
 
      </section>






    </div>
  );
}

export default connect(({ login, settings }) => ({ login, settings }), {
  getSettingsPaymentsButtons: getSettingsPaymentsButtonsAction,

  addRuleZone: addRuleZoneAction,
  updateSettingsPayments: updateSettingsPaymentsAction
})(DeliveryPaymentsPage);
