import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import UiInputText from "../../../components/inputs/UiInputText";

import UiDropDown from "../../../components/inputs/UiDropDown";
import UiExpandebleList from "../../../components/inputs/UiExpandebleList";


import UiButtonColor from "../../../components/button/UiButtonColor";


import { addCatalogTag as addCatalogTagAction } from "../../../redux/modules/tags";
import { getCategoryList as getCategoryListAction } from "../../../redux/modules/category";
import { getFilterParams as getFilterParamsAction } from "../../../redux/modules/storage";

import {
  inArrayValueByKey,
  unCheckInArray,
} from "../../../utilities/array";

import "../MenuStyle.scss";
 

function SearchTagAddPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tag, setTag] = useState({
    isEdit: false,
    title: "",
    category_id: null,
    category_filter: null,
    description: "",
  });
  const [filterList, setFilterList] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    updateTagObj("category_id", id)
  }, [id]);

  useEffect(() => {
    props.getCategoryList().then((data) => {
      data.unshift({ id: null, title: "" });
      setCategoriesList(data);
    });
    props.getFilterParams().then((data) => {
      setFilterList(data);
    });
  }, []);


  /**
 *
 * @param {string} key
 * @param {any} val
 */
  function updateTagObj(key, val) {
    let _obj = tag;
    _obj[key] = val;
    setTag({ ..._obj });
  }

  /**
   *
   * @returns String
   */
  function convertFilterValue() {
    let val = [];
    filterValues.map((item) => {
      if (item.value.length > 0) val.push(`${item.key}=${item.value.join()}`);
    });
    return val.join("&");
  }

 
  /**
   *
   * @param {string} key
   * @param {any} val
   * @param {boolean} replace
   */
  function updateFilter(key, val, replace = false) {
    let _obj = filterValues;
    let f = true;
    _obj.map((item) => {
      if (item.key == key) {
        item.value = replace ? [val] : unCheckInArray(item.value, val);
        f = false;
      }
    });
    if (f) _obj.push({ key: key, value: [val] });

    setFilterValues(_obj);
  }

  let filterItemsList = filterList.map((item, index) => {
    return <UiExpandebleList
      key={index}
      item={item}
      filterValues={filterValues}
      updateFilter={(item, filItem) => {
        updateFilter(item, filItem);
      }}
    />;
  });

  return (
    <div id="page" className="product-add-page">
      <div className="product-add-wrap">
        <div className="product-add-about add">
          <div className="product-add-about-title">
            <div className="product-add-about-title-wrap">
              <h3>Добавить тэг к категории</h3>
            </div>
          </div>
          <div className="product-add-notes">
            <div className="product-add-notes-list-wrap">
              <div className="product-add-info">
                <div className="product-add-info-form">
                  <UiInputText
                    label="Название:"
                    placeholder="Название"
                    value={tag.title}
                    onChange={(val) => {
                      updateTagObj("title", val);
                    }}
                  />
                </div>

                <div className="product-add-info-form">
                  <h3>Показывать товары по категории</h3>
                  <UiDropDown
                    label="Категория товаров:"
                    value={categoryValue}
                    items={categoriesList}
                    sort={true}
                    onChange={(val) => {
                      setCategoryValue(
                        inArrayValueByKey(categoriesList, "title", val)?.id
                      );

                      updateFilter(
                        "category",
                        inArrayValueByKey(categoriesList, "title", val)?.id,
                        true
                      );
                    }}
                  />
                </div>

                <div className="product-add-info-form">
                  <h3>Фильтры</h3>
                  <div>{filterItemsList}</div>
                </div>


                <div className="product-add-info-form product-add-info-photo">
                  <UiButtonColor
                    color="indigo"
                    text={"Создать"}
                    small={true}
                    onClick={() => {
                      if (tag.title != "") {
                        let _item = tag;
                        _item.category_filter = convertFilterValue(); 
                        props.addCatalogTag(tag).then((res) => {
                          if (res) navigate(`/edit/menu/${id}`);
                        });
                      } else {
                        alert("Заполните поля название  ");
                      }
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ menu }) => ({ menu }), {
  addCatalogTag: addCatalogTagAction,
  getCategoryList: getCategoryListAction,
  getFilterParams: getFilterParamsAction,
})(SearchTagAddPage);
