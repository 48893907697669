import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addFile, retrieveData } from "../../../services/Storage";
import { useNavigate, useParams } from "react-router-dom";

import UiInputText from "../../../components/inputs/UiInputText";
 
import UiDropDown from "../../../components/inputs/UiDropDown";
import UiExpandebleList from "../../../components/inputs/UiExpandebleList";

import UiButtonColor from "../../../components/button/UiButtonColor";

import { update as updateAction } from "../../../redux/modules/tags";
import { getCategoryList as getCategoryListAction } from "../../../redux/modules/category";
import { getFilterParams as getFilterParamsAction } from "../../../redux/modules/storage";

import { 
  inArrayValueByKey,
  unCheckInArray,
} from "../../../utilities/array";

import "../MenuStyle.scss";

function SearchTagEditPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
 
  const [tag, setTag] = useState({
    isEdit: false,
    title: "",
    category_id: null,
    category_filter: null,
    description: "",
  });

 
  const [filterValues, setFilterValues] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [categoryValueTitle, setCategoryValueTitle] = useState(null);

  const [categoriesList, setCategoriesList] = useState([]); 
  const [filterList, setFilterList] = useState([]);

 

  useEffect(() => {
    props.getCategoryList().then((data) => {
      data.unshift({ id: null, title: "" });
      setCategoriesList(data);
    });
    props.getFilterParams().then((data) => {
      setFilterList(data);
    });
  }, []);

  useEffect(() => {
    let editData = retrieveData("editMenuTag");
    if (editData) {
      console.log(editData);
      setTag(editData);

      if (editData.category_filter?.includes("category=")) { 
        let _split = editData.category_filter.split("category="); 
        setCategoryValueTitle(_split[1].split('&')[0]) 
      }

      convertFromFilterValue(editData.category_filter);
    } 
    window?.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    setCategoryValue(
      inArrayValueByKey(categoriesList, "title_url", categoryValueTitle)?.id
    );
  }, [categoriesList, categoryValueTitle])

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = tag;
    _obj[key] = val;
    setTag({ ..._obj });
  }

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateTagObj(key, val) {
    let _obj = tag;
    _obj[key] = val;
    setTag({ ..._obj });
  }

  /**
   *
   * @returns String
   */
  function convertFilterValue() {
    let val = [];
    filterValues.map((item) => {
      if (item.value.length > 0) val.push(`${item.key}=${item.value.join()}`);
    });
    return val.join("&");
  }

  /**
   * @param {String} _list
   * @returns Array
   */
  function convertFromFilterValue(_list) {
    let resultArr = [];
    if (_list) {
      let arr = _list.split("&");
      arr.map((item) => {
        let _item = item.split("=");
        if (_item.length > 0) {
          if (_item[1].length > 0) {
            resultArr.push({
              key: _item[0],
              value: _item[1].split(","),
            });
          }

        }
      });
    }
    setFilterValues(resultArr);
  }

  /**
   *
   * @param {string} key
   * @param {any} val
   * @param {boolean} replace
   */
  function updateFilter(key, val, replace = false) {
    let _obj = filterValues;
    let f = true;
    _obj.map((item) => {
      if (item.key == key) {
        item.value = replace ? [val] : unCheckInArray(item.value, val);
        f = false;
      }
    });
    if (f) _obj.push({ key: key, value: [val] });

    setFilterValues(_obj);
  }

 

  let filterItemsList = filterList.map((item, index) => {
    return <UiExpandebleList
      key={index}
      item={item}
      filterValues={filterValues}
      updateFilter={(item, filItem) => {
        updateFilter(item, filItem);
      }}
    />;
  });

  return (
    <div id="page" className="product-add-page">
      <div className="product-add-wrap">
        <div className="product-add-about add">
          <div className="product-add-about-title">
            <div className="product-add-about-title-wrap">
              <h3>Редактировать тэг</h3>
            </div>
          </div>
          <div className="product-add-notes">
            <div className="product-add-notes-list-wrap">
              <div className="product-add-info">
                <div className="product-add-info-form">
                  <UiInputText
                    label="Название:"
                    placeholder="Название"
                    value={tag.title}
                    onChange={(val) => {
                      updateOrderObj("title", val);
                    }}
                  />
                </div>
 

                <div className="product-add-about-title">
                  <div className="product-add-about-title-wrap">
                    <h3>Что показывать по тэгу </h3>
                  </div>
                </div>

                <div className="product-add-info-form">
                  <h3>Показывать товары по категории</h3>
                  <UiDropDown
                    label="Категория товаров:"
                    value={categoryValue}
                    items={categoriesList}
                    sort={true}
                    onChange={(val) => {
                      setCategoryValue(
                        inArrayValueByKey(categoriesList, "title", val)?.id
                      );

                      updateFilter(
                        "category",
                        inArrayValueByKey(categoriesList, "title", val)?.title_url,
                        true
                      );
                    }}
                  />
                </div>
                <div className="product-add-info-form">
                  <h3>Показывать товары по фильтру</h3>
                  <div>{filterItemsList}</div>
                </div>
 

    
                <div className="product-add-info-form product-add-info-photo">
                  <UiButtonColor
                    color="primary"
                    text={"Сохранит изменения и Закрыть"}
                    small={true}
                    onClick={() => {
                      if (tag.title != "") {
                        let _item = tag;
                        _item.category_filter = convertFilterValue();
                        props.update(_item).then((res) => {
                          if (res) navigate(`/edit/menu/${id}`);
                        });
                      } else {
                        alert("Заполните поля название  ");
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ category, menu }) => ({ category, menu }), {
  update: updateAction,
  getCategoryList: getCategoryListAction,
  getFilterParams: getFilterParamsAction,
})(SearchTagEditPage);
