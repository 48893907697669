import ENV from './Env.js';
 

export const makeSetting = (_data) => {
    console.log(_data);
    
    return fetch(`${ENV.API_URL}/settings/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}
 

export const updateSetting = (_data) => {
    return fetch(`${ENV.API_URL}/settings/update/id/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const updateSettingByKey = (_data) => {
    return fetch(`${ENV.API_URL}/settings/update/key`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}


export const getList = () => {
    return fetch(`${ENV.API_URL}/settings/all`, {
        method: 'GET',
        headers: ENV.getHeaders(),
    }).then(function (response) {
        return response.json();
    });
}

export const getPriceList = () => {
    return fetch(`${ENV.API_URL}/settings/get/prices`, {
        method: 'GET',
        headers: ENV.getHeaders(),
    }).then(function (response) {
        return response.json();
    });
}

export const getIntervalDate = (date) => {
    return fetch(`${ENV.API_URL}/interval/date/${date}`, {
        method: 'GET',
        headers: ENV.getHeaders(),
    }).then(function (response) {
        return response.json();
    });
}

export const updateDeliveryInterval = (_data) => {
    return fetch(`${ENV.API_URL}/interval/update/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const addDeliveryInterval = (_data) => {
    return fetch(`${ENV.API_URL}/interval/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const getZonesList = (_data) => {
    return fetch(`${ENV.API_URL}/settings/delivery/zones/list`, {
        method: 'GET',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const getStoragesZonesList = (_data) => {
    return fetch(`${ENV.API_URL}/settings/delivery/storages/zones`, {
        method: 'GET',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}



export const getZonesRulesList = (_data) => {
    return fetch(`${ENV.API_URL}/settings/delivery/rules`, {
        method: 'GET',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const updateDeliveryZone = (_data) => {
    return fetch(`${ENV.API_URL}/settings/delivery/storages/update/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const makeDeliveryZone = (_data) => {
    return fetch(`${ENV.API_URL}/settings/delivery/storages/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const updateRuleZone = (_data) => {
    return fetch(`${ENV.API_URL}/settings/delivery/rules/update/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const makeRuleZone = (_data) => {
    return fetch(`${ENV.API_URL}/settings/delivery/rules/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}
 

export const getSettingsPaymentsButtonsList = () => {
    return fetch(`${ENV.API_URL}/settings/payments/all`, {
        method: 'GET',
        headers: ENV.getHeaders(), 
    }).then(function (response) {
        return response.json();
    });

}

export const updateSettingsPaymentsButtons = (_data) => {
    return fetch(`${ENV.API_URL}/settings/payments/update/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}