import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

 


import { 
  getMenuList as getMenuListAction,
  update as updateAction, 
} from "../../redux/modules/menu";

import {
  convertImages, fromArrayListKey,
  inArrayValueByKey, toArrayListKey,
  toArrayLabelListKey

} from "../../utilities/array";

import UiSearchDropDown from "../../components/inputs/UiSearchDropDown";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";


import "./HomeStaticStyle.scss";
import { storeData } from "../../services/Storage";

function HomeStaticPage(props) {
  const navigate = useNavigate();
 
  const [searchLine, setSearchLine] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);

  const clientsTitles = [
    { sort: false, title: "" }, 
    { sort: false, title: "Сортировка" },
    { sort: false, title: "Название" },
    { sort: true, title: "Показать на главной" },
    { sort: false, title: "Удалить" },
  ];

  const [tableListOrders, setTableListOrders] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    _load();
  }, []);

  useEffect(() => {
    let arr = [], filterArr = [];
    filterArr = categoriesList.filter((item) => item.is_site_menu  == 1); 
    filterArr = filterArr.sort((a, b) => (a).main_sort - (b).main_sort); 
    filterArr.map((item) => { 
        let images = convertImages(item.imgs);
        item.images = images;
        arr.push([
          {
            button: "open", onClick: () => {
              navigate(`/products/category/${item.id}`)
            }
          }, 

          {
            input: `${item.main_sort}`,
            text: `${item.main_sort}`,
            onBlurInput: (v) => {
              let _item = item;
              _item.main_sort = v;
              props.update(_item).then(() => _load());
            },
          },
          {
            text: item.title,
          },

          {
            button: item.is_site_menu ? "eye-open" : "eye",
            color: item.is_site_menu ? "teal" : "gray",
            text: item.is_site_menu == 1 ? "Да" : "Нет",
            onClick: () => {
              let _item = item;
              _item.is_site_menu = _item.is_site_menu == 1 ? 0 : 1;
              props.update(_item).then(() => _load());
            },
          },


          {
            button: item.show ? "eye-open" : "eye",
            color: "red",
            onClick: () => {
              if (window.confirm("Убрат категорию из меню элемент ?")) {
                let _item = item;
                _item.is_site_menu = 0;
                props.update(_item).then(() => _load());
              }
            },
          },
        ]); 
    });
    setTableListOrders(arr);

  }, [categoriesList]);


  function _load() {
    props.getMenuList().then((data) => {
      if (data) {
        let _data = data;
        setCategoriesList(_data);
      }
    });
  }

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Главная страница" />
        </div>
        <div className="home-info-button">

        </div>
      </section>






      <section className="page-content">
        <div className="page-search">

          <UiSearchDropDown
            label="Выбрать категорию:"
            showEmptyVal
            items={toArrayLabelListKey(categoriesList, "title")}
            onChange={(val) => {
              let _item = inArrayValueByKey(categoriesList, "id", val);
              setSelectedCategory(_item)
            }}
          />

          <UiButtonColor
            color={selectedCategory ? "indigo" : "gray"}
            text="Добавить"
            small={true}
            onClick={() => {
              if (selectedCategory) {
                let _item = selectedCategory;
                _item.is_site_menu = 1;
                props.update(_item).then(() => _load());
              }
            }}
          />
        </div>

        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          search={searchLine}
        />
      </section>



    </div>
  );
}

export default connect(
  ({ storage, login, category }) => ({
    storage,
    login,
    category,
  }),
  {
    getMenuList: getMenuListAction,
    update: updateAction,
  }
)(HomeStaticPage);
