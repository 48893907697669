import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import UiInputText from "../../components/inputs/UiInputText";
import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiSelect from "../../components/select/UiSelect";
import UiDropDown from "../../components/inputs/UiDropDown";
import UiTextArea from "../../components/inputs/UiTextArea";
import UiButtonColor from "../../components/button/UiButtonColor";

import {
    searchStorage as searchStorageAction,
} from "../../redux/modules/storage";
import {
    add as addAction, 
  } from "../../redux/modules/sales";

import Env from "../../services/Env";

import SalesConstant from "../../constants/SalesConstant";


import { formatDateYMD } from "../../utilities/date";
import {
    convertImages, 
    inArrayValueByKey,
    unCheckInArray,
} from "../../utilities/array";

import { formatSaleTypeData } from "../../utilities/sales";
import "./SalesStyle.scss";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

function SalesAddPage(props) {

    const navigate = useNavigate();
    const [order, setOrder] = useState({
        isEdit: false,

        title: "",
        text: "",
        sale_type: "fixed",
        sale_value: 0,
        start_date: null,
        end_date: null,

        storages_list: [],
    });
    const [searchLine, setSearchLine] = useState("");
    const [storageList, setStorageList] = useState([]);
    const [tableListStorages, setTableListStorages] = useState([]);
    const [tableSelectedListStorages, setSelectedTableListStorages] = useState([]);


    const storagesTitles = [
        { sort: false, title: "" },
        { sort: false, title: "" },
        { sort: true, title: "Название" },
        { sort: true, title: "Цена" },
        { sort: false, title: "Остаток" },
    ];



    useEffect(() => {
        if (searchLine != "") {
            _search(searchLine);
        } else {

        }
    }, [searchLine]);

    useEffect(() => {
        let arr = [];
        storageList.map((item) => {
          let images = convertImages(item.imgs);
          item.images = images;
          arr.push([
            {
              button: "check",
              onClick: () => {  
                let arr =  JSON.parse( JSON.stringify(unCheckInArray( order.storages_list, item ) ));
                updateOrderObj("storages_list",  arr)
              },
            },
            { image: images[0] ? Env.PUBLIC_URL + images[0] : null }, 
            { text: item.title }, 
            { text: `${item.price} ₽` }, 
            { text: `${item.storage_left} шт` }, 
           
          ]);
        });
        setTableListStorages(arr);
      }, [storageList]);

      useEffect(() => {
        let arr = [];
        order.storages_list.map((item) => {
          let images = convertImages(item.imgs);
          item.images = images;
          arr.push([
            {
              button: "check",
              color: "green",
              onClick: () => { 
                updateOrderObj("storages_list", unCheckInArray( order.storages_list, item ) )
              },
            },
            { image: images[0] ? Env.PUBLIC_URL + images[0] : null }, 
            { text: item.title }, 
            { text: `${item.price} ₽` }, 
            { text: `${item.storage_left} шт` }, 
           
          ]);
        });
        setSelectedTableListStorages(arr);
      }, [order.storages_list]);


    /**
     *
     * @param {string} key
     * @param {any} val
     */
    function updateOrderObj(key, val) {
        let _obj = order;
        _obj[key] = val;
        setOrder({ ..._obj });
    }

    function _search(_searchLine) {
        setSearchLine(_searchLine);
        props.searchStorage(_searchLine).then((data) => {
            if (data) setStorageList(data);
        });
    }


    return (
        <div id="page" className="product-add-page">
            <div className="product-add-wrap">
                <div className="product-add-about add">
                    <div className="product-add-about-title">
                        <div className="product-add-about-title-wrap">
                            <h3>Добавить акцию</h3>
                        </div>
                    </div>
                    <div className="product-add-notes">
                        <div className="product-add-notes-list-wrap">
                            <div className="product-add-info">
                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Название акции:"
                                        placeholder="Название"
                                        value={order.title}
                                        onChange={(val) => {
                                            updateOrderObj("title", val);
                                        }}
                                    />
                                </div>

                                <div className="product-add-info-form">
                                    <UiTextArea
                                        label="Краткое описание"
                                        value={order.text}
                                        onChange={(val) => updateOrderObj("text", val)} />
                                </div>

                                <div className="product-add-info-form">
                                    <UiDropDown
                                        label="Тип скидки:"
                                        value={order.sale_type}
                                        items={SalesConstant.SALE_TYPES}
                                        onChange={(val) => {
                                            updateOrderObj("sale_type", inArrayValueByKey(SalesConstant.SALE_TYPES, "title", val)?.id)
                                        }}
                                    />
                                </div>

                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Значение скидки"
                                        value={order.sale_value}
                                        onChange={(val) => {
                                            updateOrderObj("sale_value", formatSaleTypeData(val, order.sale_type));

                                        }}
                                    />
                                </div>

                                <div className="product-add-info-form">
                                    <UiInputText
                                        label=" С какого числа доступна"
                                        type={"date"}
                                        value={
                                            order.start_date
                                                ? formatDateYMD(order.start_date, "-")
                                                : null
                                        }
                                        onChange={(val) => updateOrderObj("start_date", val)}
                                    />
                                </div>
                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="До какой даты доступна"
                                        type={"date"}
                                        value={
                                            order.end_date ? formatDateYMD(order.end_date, "-") : null
                                        }
                                        onChange={(val) => updateOrderObj("end_date", val)}
                                    />
                                </div>

                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="До какой даты доступна"
                                        type={"date"}
                                        value={
                                            order.end_date ? formatDateYMD(order.end_date, "-") : null
                                        }
                                        onChange={(val) => updateOrderObj("end_date", val)}
                                    />
                                </div>

                                <div className="product-add-info-form">
                                    <h3>Товары в акции</h3>
                                    {tableSelectedListStorages.length > 0 && <UiTableAdaptive
                                        pageSize={20} 
                                        titleList={storagesTitles}
                                        grid={storagesTitles.length}
                                        tableList={tableSelectedListStorages} 
                                    /> }

                                </div>
                                

                                <div className="page-search">
                                    <UiInputSearch
                                        label="Поиск товаров:"
                                        placeholder="Поиск"
                                        value={searchLine}
                                        onChange={(val) => setSearchLine(val)}
                                    />
                                </div>



                                <div className="product-add-info-form">
                                    <UiTableAdaptive
                                        pageSize={20} 
                                        titleList={storagesTitles}
                                        grid={storagesTitles.length}
                                        tableList={tableListStorages} 
                                    />

                                </div>




                                <div className="product-add-info-form product-add-info-photo">
                                    <UiButtonColor
                                        color="indigo"
                                        text={"Создать"}
                                        small={true}
                                        onClick={() => {
                                            if (
                                                order.title != "" &&
                                                order.text != "" &&
                                                order.start_date != null &&
                                                order.end_date != null &&
                                                order.storages_list.length > 0  
                                            ) { 
                                                    let _order = order; 
                                                    props.add(_order).then((res) => {
                                                        if (res) navigate(`/sales/list`);
                                                    });
                                               

                                            } else {
                                                alert("Заполните поля ");
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    );
}

export default connect(
    ({ sales, material }) => ({ material }),
    {
        add: addAction,
      

        searchStorage: searchStorageAction,
    }
)(SalesAddPage);
