
import {
  getListById,
  makeTag,
  updateTag,
} from "../../services/Tags";



const moduleName = "tags";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_FILTERED_LIST = `${moduleName}/SET_FILTERED_LIST`;
const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
  list: [],

  filteredList: [],
  error: false,
  errorType: 3,
  isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_LIST:
      return { ...state, list: payload };
    case SET_FILTERED_LIST:
      return { ...state, filteredList: payload };

    case SET_ERROR:
      return { ...state, error: payload };
    case SET_ERROR_TYPE:
      return { ...state, errorType: payload };

    case SET_API_REQUEST:
      console.log("fetching API request", payload);
      return { ...state, isFetching: payload };

    default:
      return state;
  }
};

export const setList = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LIST,
      payload: value,
    });
  } catch (error) {
    console.error(error);
  }
};


export const getTagsList = (_title) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getListById(_title).then((res) => {
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      if (res.response) {
          dispatch({
            type: SET_LIST,
            payload: res.response,
          });

          return res.response;
      } else {
        return [];
      }
     
     
    });
  } catch (error) {
    console.error(error);
  }
};



export const addCatalogTag = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return makeTag(getState().login.user.api_token, _data).then((res) => {
      console.log(res);
      if (res.response) {
      
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const update = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return updateTag(getState().login.user.api_token, _data).then(
      (res) => {
        console.log(res);
        if (res.response) {
        
        }
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
        return res.response;
      }
    );
  } catch (error) {
    console.error(error);
  }
};
