import Env from "../../services/Env";
import {
    getList, makeSetting, updateSetting,
    getPriceList, updateSettingByKey,
    getIntervalDate, updateDeliveryInterval, addDeliveryInterval,
    getZonesList, getStoragesZonesList, getZonesRulesList, updateDeliveryZone, makeDeliveryZone,

    updateRuleZone, makeRuleZone,

    getSettingsPaymentsButtonsList, updateSettingsPaymentsButtons,

} from "../../services/Setting";



const moduleName = "settings";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_INTERVAL_LIST = `${moduleName}/SET_INTERVAL_LIST`;
const SET_DELIVERY_ZONES_LIST = `${moduleName}/SET_DELIVERY_ZONES_LIST`;
const SET_DELIVERY_STORAGES_ZONES_LIST = `${moduleName}/SET_DELIVERY_STORAGES_ZONES_LIST`;
const SET_DELIVERY_RULES_LIST = `${moduleName}/SET_DELIVERY_RULES_LIST`;
const SET_PAYMENTS_LIST = `${moduleName}/SET_PAYMENTS_LIST`;

const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
    list: [],
    listInterval: [],
    deliveryZonesList: [],
    deliveryZonesRules: [],
    deliveryStoragesList: [],
    paymentsButtonsList: [],

    error: false,
    errorType: 3,
    inviteCount: 10,
    isFetching: false,

}

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_LIST:
            return { ...state, list: payload }
        case SET_INTERVAL_LIST:
            return { ...state, listInterval: payload }

        case SET_DELIVERY_ZONES_LIST:
            return { ...state, deliveryZonesList: payload }

        case SET_DELIVERY_STORAGES_ZONES_LIST:
            return { ...state, deliveryStoragesList: payload }

        case SET_DELIVERY_RULES_LIST:
            return { ...state, deliveryZonesRules: payload }
        case SET_PAYMENTS_LIST:
            return { ...state, paymentsButtonsList: payload }


        case SET_ERROR:
            return { ...state, error: payload }
        case SET_ERROR_TYPE:
            return { ...state, errorType: payload }

        case SET_API_REQUEST:
            console.log('fetching API request', payload)
            return { ...state, isFetching: payload }

        default:
            return state;
    }

}


export const setList = (value) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LIST,
            payload: value
        });
    } catch (error) {
        console.error(error);
    }
}



export const getSettingList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getList().then((res) => {
            console.log(res)
            if (res.response) {
                dispatch({
                    type: SET_LIST,
                    payload: res.response.body
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const getSettingValue = (_title) => (dispatch, getState) => {
    try {
        let val = null;
        let list = getState().settings?.list;
        if (list.length > 0) {
            list.map((item) => {
                if (item.title == _title) {
                    val = item.value;
                }
            })
        }
        return val;

    } catch (error) {
        console.error(error);
    }

}


export const addSetting = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        return makeSetting(getState().login.user.api_token, _data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getSettingList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const update = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        _data['api_token'] = getState().login.user.api_token;


        return updateSetting(_data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getSettingList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const updateByKey = (_title, _value) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        let _data = {
            api_token: getState().login.user.api_token,
            title: _title,
            value: _value
        }


        return updateSettingByKey(_data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getSettingList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const getIntervalList = (data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getIntervalDate(data).then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_INTERVAL_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const updateInterval = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        _data['api_token'] = getState().login.user.api_token;


        return updateDeliveryInterval(_data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getIntervalList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const addInterval = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        _data['api_token'] = getState().login.user.api_token;


        return addDeliveryInterval(_data).then((res) => {
            if (res.response) {
                dispatch(getIntervalList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const getDeliveryZonesList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getZonesList().then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_DELIVERY_ZONES_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const getDeliveryStoragesZonesList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getStoragesZonesList().then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_DELIVERY_STORAGES_ZONES_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}





export const getDeliveryZonesRulesList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getZonesRulesList().then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_DELIVERY_RULES_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const updateZone = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        _data['api_token'] = getState().login.user.api_token;


        return updateDeliveryZone(_data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getDeliveryStoragesZonesList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const addDeliveryZone = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        _data['api_token'] = getState().login.user.api_token;


        return makeDeliveryZone(_data).then((res) => {
            if (res.response) {
                dispatch(getDeliveryStoragesZonesList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const updateRule = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        _data['api_token'] = getState().login.user.api_token;


        return updateRuleZone(_data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getDeliveryZonesRulesList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const addRuleZone = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        _data['api_token'] = getState().login.user.api_token;


        return makeRuleZone(_data).then((res) => {
            if (res.response) {
                dispatch(getDeliveryZonesRulesList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}



export const getSettingsPaymentsButtons = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getSettingsPaymentsButtonsList().then((res) => {
            if (res.response) {
                dispatch({
                    type: SET_PAYMENTS_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

export const updateSettingsPayments = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });

        _data['api_token'] = getState().login.user.api_token;


        return updateSettingsPaymentsButtons(_data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getSettingsPaymentsButtons());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}