
/**
 * Check if availebl data
 * @param {number} value 
 * @param {string} type 
 * @returns {int}
 */
export const formatSaleTypeData  = (value, type = "fixed") => {
     let _num =  parseInt(value);
     _num = isNaN(_num) ? 0 : _num;   
     if(type == "fixed"){
        return _num;
     } else if(type == "percent"){
        return _num >= 0 ? _num : 0; 
     } else {
        return _num;
     }
}
