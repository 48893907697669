import ENV from './Env.js';


 

export const getList = (_title) => {
  return fetch(`${ENV.API_URL}/menu/tags/title/${_title}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    return response.json()
  });
}

export const getListById = (_id) => {
  return fetch(`${ENV.API_URL}/menu/tags/category/${_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    return response.json()
  });
}


export const makeTag = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(ENV.API_URL + '/menu/tags/store', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


export const updateTag = (_api, _data) => {
  _data['api_token'] = _api;

  return fetch(`${ENV.API_URL}/menu/tags/update/${_data['id']}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}

 